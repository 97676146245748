import React from "react";
import { withSubtheme } from '@starberryComponentsMui';
import Button from "@mui/material/Button";
import ActionModalDefault from '@myaccount/modals/default';
import DialogChildren from './dialogChildren';
import { useFormState } from "@myaccountServices";
import defaults from './defaults';
import CircularProgress from '@myaccount/progress/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';

const NewDocumentBtn = withSubtheme((props) => {
    const {
        btnLabelProps,
        modalTitle,
        btnLabel,
        altBtnLabel,
        altModalTitle,
        btnProps,
        showPlusBtn,
        altPlusBtn,
        btnDisabled,
        toolTip,
        postActionData,
        dialogContentTextProps,
    } = props

    const userDetails = useSelector(state => state.profile);
    const { state, services } = useFormState()
    const [canReloadData, setCanReloadData] = React.useState(false);

    const userQualify = (user) => {
        return (user?.user?.contact_id || user?.profile?.contact_id)
    }

    const isUserQualify = userQualify(userDetails);

    const label = altBtnLabel || btnLabel || btnLabelProps;
    const title = altModalTitle || modalTitle || "PROOF OF FUNDS";
    const plusBtn = (altPlusBtn === false) ? altPlusBtn : showPlusBtn

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    // if form success then reloadData
    if (state.success && !canReloadData)
        setCanReloadData(true);

    const handleClose = (event) => {
        event.preventDefault();
        services.resetForm();
        if (props?.reloadDataFun && canReloadData) {
            props.reloadDataFun();
        }
        setOpen(false);
    };

    let dialogContent = '';
    if (state.loading) {
        dialogContent = <CircularProgress />
    } else {
        dialogContent = <DialogChildren
            handleClose={handleClose}
            postActionData={postActionData}
        />
    }

    return(
        <>
            {(btnDisabled && isUserQualify) && (
                <Tooltip title={toolTip} arrow placement="top">
                    <div style={{marginBottom: '20px'}} className="toolTipContent">
                        <Button {...btnProps} className="btn report-fault-btn" onClick={handleClickOpen} disabled={btnDisabled || false}>
                            {plusBtn && <AddIcon sx={{ mr: 1 }} />}
                            {label}
                        </Button>
                    </div>
                </Tooltip>
            )}
            {(!btnDisabled && isUserQualify) && (
                <Button {...btnProps} className="btn report-fault-btn" onClick={handleClickOpen}>
                    {plusBtn && <AddIcon sx={{ mr: 1 }} />}
                    {label}
                </Button>
            )}
            <ActionModalDefault
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                children={dialogContent}
                loading={state.loading}
                title={title}
                dialogContentTextProps={dialogContentTextProps}
            />
        </>
    )
}, 'newDocumentBtn', defaults)

export default NewDocumentBtn;
